import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"

const HomeContentMobile = () => {
    const { template_page_content } = useSelector((state) => state.globalReducer);
    const page_content = template_page_content['home'];

    return (
        (page_content.section_3 || page_content.section_4) && <Box className="hide_on_desktop_flex"
        sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            fontSize: 12,
            color: '#616467',
            margin: {
                xs: '20px 0px 8px 0px',
                md: '0px 0px 0px 0px',
            }

        }}>
            <Box className="pl-3 pr-3">
                {page_content.section_3 && (
                        <>
                            <Typography className="f-size_3 mb-2 uppercase text-center" component="h2">{page_content.section_3.title}</Typography>
                            <Typography className="f-size_2">
                                {page_content.section_3.content}
                            </Typography>
                        </>
                    )
                }

                {page_content.section_4 && (
                    <>
                        <Typography className="f-size_3 mt-3 mb-2 uppercase text-center" component="h2">{page_content.section_4.title}</Typography>
                        <Typography className="f-size_2">
                            {page_content.section_4.content}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default HomeContentMobile